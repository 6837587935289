<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="flex-def flex-zCenter flex-cCenter">
      <el-container style="max-width: 1200px;margin-top: 2rem;box-shadow: 1px 1px 5px 1px #ededed;border-radius: .5rem;overflow: hidden">
        <el-aside width="7rem" style="background-color: #ededed;">
          <el-menu
              background-color="#ededed"
              style="border: unset;"
              :router="true"
              active-text-color="teal"
              text-color="#909399"
              :default-active="$route.path"
          >
            <el-menu-item
                v-for="(item,key) in menu" :key="key"
                :index="item.path"
            >
              <template slot="title">
                <div style="text-align: center">{{ item.title }}</div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main style="min-height: 50rem;background-color: #FFFFFF">
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
import SimpleHeader from "@/components/layouts/SimpleHeader";
export default {
  name: "index",
  components: {SimpleHeader},
  data(){
    return{
      menu:[
        {
          title:"平台管理",
          path:"/founder/uni",
        },
        {
          title:"账号管理",
          path:"/founder/account",
        },
        {
          title:"站点设置",
          path:"/founder/site",
        },
        {
          title:"系统更新",
          path:"/founder/sys",
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>